import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useIntersectionObserver, useRerender, useThrottledCallback } from '@react-hookz/web';

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Progress, Row, Space, Tooltip, Typography } from 'antd';
import { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import dayjs from 'dayjs';

import {
  ActionsDocument,
  ActionsFolders,
  AddDocumentsBtn,
  DeleteConfirmModal,
  DocumentStatusLabel,
  DocumentTypeIcon,
  EditFolderName,
  FiltersSection,
  Input,
  makeSortingControllerHelper,
  MoveDocumentToFolderModal,
  NoContentPlaceholder,
  NoDataPlaceholder,
  Pagination,
  Put,
  Select,
  SelectProps,
  SendToEmail,
  SortingController,
  Table,
  Text
} from 'components';
import { DocumentsLabels } from './MainLayout/utils';
import {
  dashDateFormat,
  dateTimeHyphenFormat,
  format,
  getNotificationError,
  getRoute,
  getVisibilityByDocumentStatus,
  hasActiveFilters,
  hasReadOnlyDocuments,
  hasPrivateDocuments,
  mimeTypeToDocumentType,
  palette,
  hasSendedDocuments,
  isApproveDocumentsDisabled,
  withValues
} from 'utils';
import { AxiosError } from 'axios';
import { FoldersContext, NotificationContext, ThemeContext } from 'contexts';
import { documents, documentsQueries } from 'api';
import { DownloadFiles } from 'api/documents.types';
import { useDownloadFiles, useQueryParams } from 'hooks';
import { CloseBig, DownloadAlt, FileExcel, Locked, Mail, ShortRight } from 'icons';
import { $Object, APIDocument, Directory, DirectoryType, DocumentStatus } from 'types';
import { onFilterChange } from 'hooks/useFilter';
import { DocumentsPageFiltersInterface } from 'features/partener/pages/DocumentsPage';
import { ActionBtn } from './HeaderActions';

export interface PartnerDocumentsTableProps {
  filters: $Object;
  onFiltersChange: onFilterChange<DocumentsPageFiltersInterface>;
  showFolderColumn?: boolean;
  hasStateFilter?: boolean;
}

export const PartnerDocumentsTable = ({
  filters,
  onFiltersChange,
  showFolderColumn = true,
  hasStateFilter
}: PartnerDocumentsTableProps) => {
  const params = useParams();
  const { t } = useTranslation();
  const queryParams = useQueryParams();
  const queryClient = useQueryClient();
  const lastItemRef = useRef<HTMLDivElement>(null);

  const folderName = params['*'];

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);
  const { fetchNextFolderPage, folderData, isFolderMutateLoading, setSelectedTreeFilter } =
    useContext(FoldersContext);

  const progressRef = useRef<Record<string, number>>({});
  const progress = progressRef.current;

  const rerender = useRerender();

  const [selectedDocuments, setSelectedDocuments] = useState<APIDocument[]>([]);
  const [documentToDelete, setDocumentToDelete] = useState<APIDocument>();
  const [documentToMove, setDocumentToMove] = useState<APIDocument[]>();
  const [isBulkDeleteModalOpen, setBulkDeleteModalOpen] = useState(false);
  const [selectedAll, setSelectedAll] = useState(false);
  const [excludedRows, setExcludedRows] = useState<number[]>([]);
  const [sendToEmailParams, setSendToEmailParams] = useState<DownloadFiles | null>(null);

  const throttledRerender = useThrottledCallback(rerender, [], 500);

  const setProgress = (newProgress: React.SetStateAction<Record<string, number>>) => {
    progressRef.current =
      newProgress instanceof Function ? newProgress(progressRef.current) : newProgress;

    throttledRerender();
  };

  const { created_at_after, created_at_before, ...restFilters } = filters;

  const selectedDocumentsKeys = selectedDocuments?.map(({ id }) => id);
  const isMultipleFiles = selectedDocuments?.length > 1 || selectedAll;

  const createdAtFilters = {
    ...(created_at_after && { created_at_after: dayjs(created_at_after).format(dashDateFormat) }),
    ...(created_at_before && { created_at_before: dayjs(created_at_before).format(dashDateFormat) })
  };

  const queryDocumentParams = withValues({
    ...restFilters,
    ...createdAtFilters,
    state: queryParams.documents_state
  });

  const { data = { results: [], count: 0 }, isLoading } = useQuery({
    ...documentsQueries.getList({
      ...queryDocumentParams,
      folder_name: folderName
    }),
    onSuccess: ({ results }) => {
      if (selectedDocumentsKeys.length) {
        setSelectedDocuments?.(results?.filter(({ id }) => selectedDocumentsKeys.includes(id)));
      }
    }
  });

  const { data: foldersListData, isLoading: isFoldersLoadingListDefault } = useQuery({
    ...documentsQueries.getDirectoriesList({
      parent: filters.directory,
      per_page: 1000,
      search: filters.search,
      ordering: filters.ordering?.replace('title', 'name')
    }),
    select: (data) => {
      return {
        children: data?.results?.map(({ children, ...rest }) => ({ ...rest, children: undefined }))
      };
    },
    enabled: !!filters.documents_archive && !filters.directory
  });

  const isFoldersLoading =
    ((!filters.directory && isFoldersLoadingListDefault) ||
      (!!filters.directory && isFolderMutateLoading)) &&
    !!filters.documents_archive;

  const foldersData = useMemo(
    () =>
      filters.directory
        ? {
            children: folderData?.children.map((directory) => ({
              ...directory,
              children: directory.children
            }))
          }
        : foldersListData,
    [filters.directory, folderData, foldersListData]
  );

  const hasAnyReadOnlyDocumentsSelected = hasReadOnlyDocuments(
    data?.results,
    selectedAll && filters.documents_state
      ? data?.results?.map(({ id }) => id)
      : selectedDocumentsKeys
  );

  const hasAnySendedSelected = hasSendedDocuments(data?.results, selectedDocumentsKeys);

  const hasAnyPrivateDocument = hasPrivateDocuments(data?.results, selectedDocumentsKeys);

  const documentsStatsQuery = useQuery(documentsQueries.getPartnerStats());

  const isApproveDisabled = isApproveDocumentsDisabled(data?.results, selectedDocumentsKeys);

  const onClearSelection = () => {
    setSelectedAll(false);
    setSelectedDocuments([]);
    setExcludedRows([]);
  };

  useEffect(() => {
    if (!!(excludedRows.length - data.count)) return;

    onClearSelection();
  }, [excludedRows.length, data.count]);

  useEffect(() => {
    onClearSelection();
  }, [filters.documents_state]);

  const { handleDownloadFiles } = useDownloadFiles();

  const { mutateAsync: downloadMutate } = useMutation(documents.downloadFiles, {
    onSuccess: (data) => {
      handleDownloadFiles(data);
      onClearSelection();
    }
  });

  const { mutateAsync: downloadExcelMutate } = useMutation(documents.downloadExcelFiles, {
    onSuccess: (data) => {
      handleDownloadFiles(data, true);
      onClearSelection();
    }
  });

  const getSortingControllerProps = makeSortingControllerHelper(filters.ordering, (value) =>
    onFiltersChange({ ordering: value })
  );

  const invalidateDocumentQueries = () => {
    queryClient.invalidateQueries(documentsQueries.getList().queryKey);
    queryClient.invalidateQueries(documentsQueries.getPartnerStats().queryKey);
  };

  const sendToApolloMutation = useMutation(documents.sendToApollo, {
    onSuccess: () => {
      notification.success({
        message: t('sentDocuments', { count: selectedDocuments.length }),
        placement: 'bottomRight'
      });

      invalidateDocumentQueries();
      setSelectedDocuments([]);
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
    }
  });

  const deleteMutation = useMutation(() => documents.delete(documentToDelete?.id || 0), {
    onSuccess: () => {
      invalidateDocumentQueries();
      setDocumentToDelete(undefined);

      if (selectedDocuments.find(({ id }) => id === documentToDelete?.id))
        setSelectedDocuments((prev) => prev.filter(({ id }) => id !== documentToDelete?.id));
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
    }
  });

  const deleteBulkMutation = useMutation(
    () =>
      documents.deleteBulk({
        ...queryDocumentParams,
        ids: selectedDocumentsKeys,
        exclude_ids: excludedRows
      }),
    {
      onSuccess: () => {
        invalidateDocumentQueries();
        setSelectedDocuments([]);
        setBulkDeleteModalOpen(false);
      }
    }
  );

  const intersection = useIntersectionObserver(lastItemRef.current);

  useEffect(() => {
    if (!intersection?.isIntersecting || !(filters.documents_archive && filters.directory)) return;
    fetchNextFolderPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intersection?.isIntersecting, filters.documents_archive, filters.directory]);

  const columns = useMemo<ColumnsType<APIDocument & Directory>>(
    () => [
      {
        className: 'absolute',
        render: (_, __, index) =>
          foldersData && index + 1 === foldersData?.children?.length ? (
            <div ref={lastItemRef} />
          ) : null
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('title')}>
            {t('documentName')}
          </SortingController>
        ),
        width: '50%',
        render: ({ title, id, size, state, folder_name, name, ...record }) =>
          !('children' in record) ? (
            <Row align="middle" gutter={14}>
              <Col span={2}>
                <DocumentTypeIcon type={mimeTypeToDocumentType(title)} />
              </Col>
              <Col span={22}>
                <Row style={{ marginLeft: '10px' }}>
                  <Tooltip overlayClassName="sm-tooltip" title={title}>
                    <Col span={24}>
                      <Link
                        to={getRoute('PartnerDocumentItemPage', {
                          query: filters,
                          id
                        })}
                      >
                        <Text size="lg">{title}</Text>
                      </Link>
                    </Col>
                  </Tooltip>

                  <Col span={24}>
                    {Object.hasOwn(progress, folder_name ? `${folder_name}/${title}` : title) &&
                    state === DocumentStatus.pending ? (
                      <Progress
                        showInfo={false}
                        percent={progress?.[folder_name ? `${folder_name}/${title}` : title] || 0}
                      />
                    ) : (
                      <Text size="sm" color={palette['grey-60']}>
                        {size}
                      </Text>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Link
              to={getRoute('PartnerDocumentsPage', {
                query: {
                  page: filters.page,
                  per_page: filters.per_page,
                  directory: id,
                  documents_archive: filters.documents_archive,
                  '--selectedTree': setSelectedTreeFilter(id, filters)
                }
              })}
            >
              <EditFolderName
                id={id}
                type={record.type}
                userId={record.user}
                allowEdit={!record.created_by_client}
              >
                {name}
              </EditFolderName>
            </Link>
          )
      },
      ...(showFolderColumn
        ? ([
            {
              title: (
                <SortingController {...getSortingControllerProps('directory')}>
                  Folder
                </SortingController>
              ),
              dataIndex: ['directory', 'name'],
              render: (folderName: string, record) => (
                <Tooltip overlayClassName="sm-tooltip" title={folderName}>
                  <Text size="lg">
                    <Put condition={!!folderName}>
                      <Link
                        onClick={() => onFiltersChange({ directory: record?.directory?.id })}
                        to={getRoute('PartnerDocumentsPage', {
                          query: { ...filters, directory: record?.directory?.id }
                        })}
                      >
                        {folderName}
                      </Link>
                    </Put>
                  </Text>
                  <Text size="sm" color={palette['grey-60']}>
                    <Put condition={!!record.folder_files_count}>
                      {record.folder_files_count} {t('documents')}
                    </Put>
                  </Text>
                </Tooltip>
              )
            }
          ] as ColumnsType<APIDocument & Directory>)
        : []),
      {
        title: (
          <SortingController {...getSortingControllerProps('created_at')}>
            {t('uploadDate')}
          </SortingController>
        ),
        dataIndex: 'created_at',
        render: (createdAt) => (
          <Tooltip overlayClassName="sm-tooltip" title={format(createdAt)}>
            <Text size="lg">{format(createdAt)}</Text>
            <Text size="sm" color={palette['grey-60']}>
              {format(createdAt, 'HH:mm A')}
            </Text>
          </Tooltip>
        )
      },
      {
        title: (
          <SortingController {...getSortingControllerProps('state')}>
            {t('documentState')}
          </SortingController>
        ),
        dataIndex: 'state',
        render: (state, record) =>
          !('children' in record) ? <DocumentStatusLabel status={state} /> : null
      },
      {
        width: 70,
        dataIndex: 'id',
        render: (_, record) => {
          const { hideActions, hideReplace, hideDelete } = getVisibilityByDocumentStatus(
            record.state
          );

          if ('children' in record) {
            return <ActionsFolders hideDelete={record.created_by_client} folder={record} />;
          }

          return hideActions ? (
            <Locked fontSize={16} fill={currentTheme['grey-60']} />
          ) : (
            <ActionsDocument
              document={record}
              setProgress={setProgress}
              setDocumentToDelete={setDocumentToDelete}
              setDocumentToMove={setDocumentToMove}
              setSelectedDocuments={setSelectedDocuments}
              hideDelete={hideDelete}
              hideReplace={hideReplace}
              hasReadonlyFile={hasAnyReadOnlyDocumentsSelected}
            />
          );
        }
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.results, progress, t, hasAnyReadOnlyDocumentsSelected, foldersData]
  );

  const documentStatusOptions: SelectProps['options'] = Object.entries(DocumentsLabels).map(
    (entry) => ({
      value: entry[0],
      label: t(entry[1].label)
    })
  );

  const onDocumentStatusChange = (value: string | undefined) => {
    onFiltersChange({
      documents_state: value === DocumentStatus.total ? undefined : value
    });
  };

  const onSendToApolloClick = () => {
    sendToApolloMutation.mutate({ ids: selectedDocumentsKeys, exclude_ids: excludedRows });
  };

  const onDownloadDocuments = async () => {
    if (isMultipleFiles) {
      const dateAndTimestamp = dayjs().format(dateTimeHyphenFormat);

      await downloadMutate({
        ...queryDocumentParams,
        ids: selectedDocumentsKeys,
        exclude_ids: excludedRows,
        name: `SmartPortal-export-${dateAndTimestamp}.zip`
      });
    } else if (!isMultipleFiles) {
      const data = await documents.getById(selectedDocuments?.[0].id);

      await downloadMutate({ ids: selectedDocuments?.[0]?.id, name: data.title });
    }
  };

  const onDownloadExcel = async () => {
    const dateAndTimestamp = dayjs().format(dateTimeHyphenFormat);

    await downloadExcelMutate({
      ...queryDocumentParams,
      ids: selectedDocumentsKeys,
      exclude_ids: excludedRows,
      name: `SmartPortal-export-${dateAndTimestamp}.xlsx`
    });
  };

  const onSendToEmail = () => {
    const selectedDocumentsKeys = selectedDocuments.map(({ id }) => id);

    setSendToEmailParams({
      ...queryDocumentParams,
      ids: selectedDocumentsKeys,
      exclude_ids: excludedRows
    });
  };

  const rowSelection: TableRowSelection<APIDocument & Directory> = {
    selectedRowKeys: selectedAll
      ? data?.results.map(({ id }) => id).filter((id) => !excludedRows.includes(id))
      : selectedDocumentsKeys,
    onChange: (_, selectedDocuments) => !selectedAll && setSelectedDocuments(selectedDocuments),
    getCheckboxProps: (record) => ({ disabled: 'children' in record }),
    onSelect: (rec, selected) => {
      if (!selectedAll) return;
      setSelectedDocuments([]);
      setExcludedRows((value) =>
        !selected ? [...value, rec.id] : value.filter((id) => id !== rec.id)
      );
    },
    onSelectAll: (selected, __, changeRows) => {
      if (!selectedAll) return;
      setSelectedDocuments([]);
      setExcludedRows((value) =>
        selected
          ? value.filter((id) => !changeRows.find((item) => item.id === id))
          : [...value, ...changeRows.map(({ id }) => id)]
      );
    }
  };

  const onMoveFolder = () => {
    setDocumentToMove(undefined);
    setSelectedDocuments([]);
  };

  const showFoldersData =
    filters.page === 1 &&
    !(filters.documents_state && !filters.directory) &&
    filters.documents_archive;

  return (
    <NoContentPlaceholder
      show={
        !data?.results.length &&
        !isLoading &&
        !hasActiveFilters(filters) &&
        documentsStatsQuery.data?.total === 0 &&
        !foldersData?.children?.length &&
        isFoldersLoading
      }
      placeholder={
        <NoDataPlaceholder
          mainIcon="file-black-outline"
          leftIcon="document-text"
          onLeftClick={() => null}
          rightIcon="document-pdf"
          onRightClick={() => null}
          description={t('noUploadedDocument')}
          bottomContent={
            <AddDocumentsBtn
              folderName={folderName}
              filters={filters}
              setProgress={setProgress}
              folderActionDisabled={foldersData?.children?.[0]?.type === DirectoryType.SIMPLE}
            />
          }
        />
      }
    >
      <DeleteConfirmModal
        mutation={deleteBulkMutation}
        title={t('deleteDocuments')}
        open={isBulkDeleteModalOpen}
        onCancel={() => setBulkDeleteModalOpen(false)}
      >
        <Typography.Text style={{ color: currentTheme['grey-40'], fontSize: '0.875rem' }}>
          {t('approveDocumentBulkDelete', {
            length: selectedAll ? data?.count - excludedRows.length : selectedDocuments.length
          })}
        </Typography.Text>
      </DeleteConfirmModal>

      <DeleteConfirmModal
        mutation={deleteMutation}
        open={!!documentToDelete}
        title={t('deleteDocument')}
        onCancel={() => setDocumentToDelete(undefined)}
      >
        <Text size="md">{t('approveDocumentDelete')}</Text>

        <Text size="md" strong>
          {documentToDelete?.title}
        </Text>
      </DeleteConfirmModal>

      <MoveDocumentToFolderModal
        open={!!documentToMove}
        title={
          isMultipleFiles
            ? t('moveMultipleFilesTitle', { count: selectedDocuments?.length })
            : t('moveFile')
        }
        onCancel={() => setDocumentToMove(undefined)}
        onMoveFolder={onMoveFolder}
        documentsToMove={isMultipleFiles ? selectedDocuments : documentToMove}
      />

      <SendToEmail
        params={sendToEmailParams}
        onCancel={() => setSendToEmailParams(null)}
        onSuccess={onClearSelection}
      />

      <FiltersSection
        leftSide={
          <Space size={16}>
            {hasStateFilter && (
              <Select
                placeholder={t('all')}
                value={filters.documents_state}
                onChange={onDocumentStatusChange}
                options={documentStatusOptions}
                popupMatchSelectWidth={false}
                allowClear
              />
            )}
            <Input
              prefix="search"
              style={{ width: 420 }}
              placeholder={t('searchDocuments')}
              value={filters.search}
              onChange={(e) => onFiltersChange({ search: e.target.value })}
            />
          </Space>
        }
        rightSide={
          <Space size={16}>
            {(selectedDocuments.length > 0 || selectedAll) && (
              <>
                {(selectedDocuments.length > 0 || selectedAll) && (
                  <ActionBtn icon={<CloseBig />} action={onClearSelection} />
                )}

                {selectedAll && !excludedRows.length ? (
                  <Button type="link" onClick={onClearSelection}>
                    {t('clearAll')}
                  </Button>
                ) : (
                  <Button
                    type="link"
                    onClick={() => {
                      setSelectedAll(true);
                      setExcludedRows([]);
                      setSelectedDocuments([]);
                    }}
                  >
                    {t('selectAll', { count: data.count })}
                  </Button>
                )}

                <ActionBtn activeButton icon={<DownloadAlt />} action={onDownloadDocuments} />
                <ActionBtn activeButton icon={<FileExcel />} action={onDownloadExcel} />
                <ActionBtn activeButton icon={<Mail />} action={onSendToEmail} />

                <>
                  <Tooltip
                    overlayClassName="sm-tooltip"
                    title={isApproveDisabled && t('wrongDocumentType')}
                  >
                    <Button
                      onClick={onSendToApolloClick}
                      type="primary"
                      loading={sendToApolloMutation.isLoading}
                      disabled={isApproveDisabled}
                    >
                      {t('approve')}
                    </Button>
                  </Tooltip>

                  {!hasAnyReadOnlyDocumentsSelected &&
                    !hasAnyPrivateDocument &&
                    !hasAnySendedSelected &&
                    !selectedAll && (
                      <Button
                        onClick={onSendToApolloClick}
                        disabled={sendToApolloMutation.isLoading}
                        type="link"
                        loading={sendToApolloMutation.isLoading}
                      >
                        {t('sendDocuments')} ({selectedDocuments.length})
                        <ShortRight fontSize={20} />
                      </Button>
                    )}

                  <Button
                    disabled={hasAnyReadOnlyDocumentsSelected}
                    onClick={() => setBulkDeleteModalOpen(true)}
                  >
                    {t('delete')}
                  </Button>
                </>
              </>
            )}

            <AddDocumentsBtn
              folderName={folderName}
              filters={filters}
              setProgress={setProgress}
              folderActionDisabled={!!folderData?.client_directory}
            />
          </Space>
        }
      />

      <Table
        loading={isLoading || isFoldersLoading}
        dataSource={
          [
            ...(showFoldersData ? foldersData?.children || [] : []),
            ...(data?.results || [])
          ] as (APIDocument & Directory)[]
        }
        columns={columns}
        rowSelection={rowSelection}
        rowKey="id"
        pagination={false}
        rowClassName={(_, index) =>
          foldersData && index + 1 === foldersData?.children?.length ? 'last-item' : ''
        }
      />

      <Pagination
        pageSize={filters.per_page}
        current={filters.page}
        total={data?.count}
        onChange={onFiltersChange}
      />
    </NoContentPlaceholder>
  );
};
