export const ro = {
  documentName: 'Nume document',
  folder: 'Folder',
  uploadDate: 'Data incarcare',
  documentState: 'Stare document',
  noPartnerDocuments: 'Momentan niciunul din partenerii tai <br /> nu a urcat un document.',
  searchDocuments: 'Cauta documente',
  evolutionGraph: 'Grafic evolutie',
  last12Months: 'Ultimele 12 luni',
  last6Months: 'Ultimele 6 luni',
  last3Months: 'Ultimele 3 luni',
  lastMonth: 'Ultima luna',
  january: 'Ianuarie',
  february: 'Februarie',
  march: 'Martie',
  april: 'Aprilie',
  may: 'Mai',
  june: 'Iunie',
  july: 'Iulie',
  august: 'August',
  september: 'Septembrie',
  october: 'Octombrie',
  november: 'Noiembrie',
  december: 'Decembrie',
  from: 'De la',
  to: 'Pâna la',
  documents: 'Documente',
  partners: 'Parteneri',
  clients: 'Clienti',
  yourProfile: 'Profilul tau',
  changePassword: 'Schimba parola',
  logOut: 'Iesire din cont',
  legend: 'Legenda',
  documentIsUploading: 'Documentul se incarca sau se trimite',
  documentWasUploaded: 'Documentul a fost urcat in Portal.',
  documentIsProcessing: 'Documentul se afla in procesare si este blocat pentru alte actiuni.',
  documentProcessed: 'Documentul a fost procesat.',
  documentNeedInfoDescription: 'Documentul are nevoie de clarificari.',
  documentNeedReplacementDescription: 'Documentul trebuie inlocuit.',
  documentNotNeeded: 'Documentul nu este necesar clientului.',
  documentCantBePassed: 'Documentul nu a putut fi transmis catre Apollo.',
  partnerActiveDescription:
    'Partenerul tau este activ cu toate persmisiunile de gestionare documente in Portal.',
  partnerPendingDescription:
    'Partenerul tau este activ cu toate persmisiunile de gestionare documente in Portal.',
  partnerInactiveDescription: 'In acest moment Partenerul tau nu poate urca documente in Portal.',
  partnerDeletedDescription:
    'Partenerul tau nu mai poate intra in Portal, dar ii poti vedea documentele incarcate',
  clientActiveDescription:
    'Clientul tau este activ cu toate persmisiunile de gestionare documente in Portal.',
  clientPendingDescription:
    'Clientul tau este activ cu toate persmisiunile de gestionare documente in Portal.',
  clientInactiveDescription: 'In acest moment Clientul tau nu poate adauga Parteneri.K',
  clientDeletedDescription: 'Clientul tau nu mai poate intra in Portal',
  total: 'Toate',
  totalLabel: 'Toate documentele',
  pending: 'Se incarca',
  pendingLabel: 'Documente in process de incarcare',
  uploaded: 'Incarcate',
  uploadedLabel: 'Documente incarcate',
  processing: 'In procesare',
  processingLabel: 'Documente in procesare',
  needReplacement: 'Inlocuire',
  needReplacementLabel: 'Documente de inlocuit',
  needInfo: 'Solicită detalii',
  needInfoLabel: 'Documente cu detalii solicitate',
  done: 'Procesate',
  doneLabel: 'Documente procesate',
  failed: 'Eșuat',
  failedLabel: 'Documente eșuate',
  deleted: 'Sterse',
  deletedLabel: 'Documente sterse',
  rejected: 'Respins',
  rejectedLabel: 'Documente respinse',
  all: 'Toti',
  allPartners: 'Toti partenerii',
  active: 'Activi',
  activePartnersLabel: 'Parteneri activi',
  inactive: 'Inactivi',
  inactivePartnersLabel: 'Parteneri inactivi',
  pendingUser: 'In asteptare',
  pendingPartnersLabel: 'Parteneri in asteptare',
  deletedUser: 'Stersi',
  deletedPartnersLabel: 'Parteneri stersi',
  allClients: 'Toti clientii',
  activeClientsLabel: 'Clienti activi',
  inactiveClientsLabel: 'Clienti inactivi',
  pendingClientLabel: 'Clienti in asteptare',
  deletedClientsLabel: 'Clienti stersi',
  documentPending: 'Se incarca',
  documentUploaded: 'Incarcat',
  documentProcessing: 'In procesare',
  documentDone: 'Procesat',
  documentNeedReplacement: 'Inlocuire',
  documentNeedInfo: 'Solicita detalii',
  documentFailed: 'Eșuat',
  documentDeleted: 'Sters',
  documentRejected: 'Respins',
  documentMerged: 'Concatenat',
  documentSplit: 'Împărțit',
  documentTotal: 'Total',
  replace: 'Înlocuiește',
  delete: 'Șterge',
  noUploadedDocument: 'Momentan nu ai urcat niciun document.',
  deleteDocuments: 'Sterge documente',
  deleteDocument: 'Sterge document',
  approveDocumentDelete: 'Esti sigur ca vrei sa stergi documentul',
  approveDocumentBulkDelete: 'Esti sigur ca vrei sa stergi cele {{length}} documente selectate?',
  sendDocuments: 'Trimite documentele',
  rejectDocument: 'Document respins',
  document: 'Document',
  add: 'Adauga',
  resultsPerPage: 'rezultate pe pagina',
  cantMarkNotificationAsRead: 'Nu putem marca notificarea ca citita acum',
  notifications: 'Notificari',
  makeClickToMarkAsRead: 'Faceți clic pentru a marca ca citit',
  resetEmailWasSent: 'Emailul de resetare a fost trimis',
  cancel: 'Anulare',
  change: 'Schimba',
  changePasswordQuestion:
    'Esti sigur ca vrei sa-ti schimbi parola? Daca da vei primi un email de confimare din care iti vei stabili o noua parola.',
  myProfile: 'Contul tau',
  requestChangePassword: 'Solicita schimbare parola',
  partnerDetails: 'Detalii partener',
  companyCif: 'Cif Companie',
  department: 'Departament',
  emailAddress: 'Adresa email',
  workspace: 'Workspace',
  activity: 'Activitate',
  profileStatus: 'Stare cont',
  profileCreatedAt: 'Data creare cont',
  lastActivity: 'Ultima activitate',
  storageSpace: 'Spatiu stocare (GB)',
  partnerName: 'Nume partener',
  edit: 'Editeaza',
  deletedPartner: 'Partener sters',
  deletedPartners: 'Partenerii stersi',
  deletePartner: 'Sterge partener',
  partnersBulkDeleteQuestion: 'Esti sigur ca vrei sa stergi {{count}} parteneri?',
  noPartnersDescription:
    'Momentan nu ai niciun partener in lista! <br /> Incepe prin a adauga manual sau importa o lista.',
  searchPartner: 'Cauta partener',
  partnerSuccessfullyAdded: 'Partenerii au fost incarcati cu success',
  uploadList: 'Urca lista',
  example: 'Exemplu',
  manual: 'Manual',
  profileAccess: 'Acces cont',
  pleaseInsertEmail: 'Te rugam introdu un email!',
  pleaseInsertPassword: 'Te rugam introdu o parola!',
  password: 'Parola',
  keelMeLogged: 'Tine-ma logat',
  forgotPasswordQuestion: 'Parola uitata?',
  logIn: 'Intrare in cont',
  portalDescription1:
    'platforma care simplifică procesul de colectare, verificare & încărcare a documentelor ce urmează a fi procesate inteligent de Apollo.',
  portalDescription2: 'Eficiență & productivitate',
  portalDescription3: 'crescute prin automatizarea end to end a procesării documentelor.',
  portalDescription4: 'Vizibilitate',
  portalDescription5: 'în timp real asupra documentelor încărcate și a stării lor de procesare.',
  portalDescription6: '100% control',
  portalDescription7:
    'asupra documentelor încărcate și a regulilor de validare înaintea procesării inteligente.',
  notFoundPageTitle: 'Pagina pierduta!',
  notFoundPageDescription: 'Ne pare rau, insa pagina pe care o cauti nu exista sau a fost mutata.',
  backToDashboard: 'Inapoi la dashboard',
  resetPassword: 'Resetare parola!',
  pleaseInputEmail: 'Te rugam sa-ti introduci email-ul.',
  receiveEmail: 'Primeste email',
  inputEmail: 'Introdu email',
  clientName: 'Nume client',
  clientDetails: 'Detalii client',
  documentsNumber: 'Numar documente',
  activeUser: 'Activ',
  inactiveUser: 'Inactiv',
  deletedUserLabel: 'Sters',
  totalUser: 'Total',
  editClient: 'Edit client',
  addClient: 'Adauga client',
  save: 'Salveaza',
  clientEditDescription:
    'Odata ce ai salvat modificarile, clientul tau va primi un mesaj de atentionare ca datele lui au fost actualizate.',
  clientAddDescription:
    'Odata ce salvati clientul, el va primi pe email un mesaj ce contine datele de acces.',
  companyName: 'Nume companie',
  cif: 'Cif',
  emailAddressLabel: 'Adresa de email(Utilizator logare)',
  apolloApiKey: 'Apollo API Key',
  domainPrefix: 'Domain prefix',
  wrongFormat: 'Format gresit',
  clientRequestedPassword: 'Clientul a solicitat parola',
  changePasswordDescription:
    'Esti sigur ca vrei ii schimbi parola? Daca da, clientul va primi un email de confimare din care isi va stabili o noua parola.',
  apolloId: 'ID Apollo',
  createProfile: 'Creare cont',
  deletedClient: 'Client sters',
  deletedClients: 'Clientii stersi',
  searchClient: 'Cauta client',
  noClientDescription:
    'Momentan nu ai niciun client in lista! <br /> Incepe prin a adauga manual sau importa o lista.',
  deleteClientQuestion: 'Esti sigur ca vrei sa stergi clientul ',
  deleteClient: 'Sterge client',
  deleteClients: 'Sterge clienti',
  bulkDeleteClientQuestion: 'Esti sigur ca vrei sa stergi {{count}} clienti?',
  addedClient: 'Client adaugat',
  editedClient: 'Client editat',
  editPartner: 'Editeaza partener',
  addPartner: 'Adauga partener',
  partnerEditDescription:
    'Odata ce ai salvat modificarile, partenerul tau va primi un email de atentionare ca datele lui au fost actualizate.',
  partnerAddDescription:
    'Odata ce salvati partenerul, el va primit pe email un mesaj care contine datele de acces.',
  partnerEmailAddress: 'Adresa de email(Partener)',
  savePassword: 'Salveaza parola',
  thisFieldIsRequired: 'Acest câmp este obligatoriu',
  newValueDoesNotMatch: 'Noua valoare nu coincide cu cimpul precedent!',
  pleaseSetYourPassword: 'Te rugam sa-ti stabilesti o parola.',
  welcome: 'Bine ai venit!',
  repeatPassword: 'Repeta parola',
  aSmallLetter: 'O litera mica',
  aBigLetter: 'O litera mare',
  aNumber: 'O cifra',
  aSymbol: 'Un caracter special',
  min8Characters: 'Minimum 8 caractere',
  downloadFolder: 'Download folder',
  'ro-ro': 'Romana',
  'ro-us': 'Engleza',
  'us-us': 'English',
  'us-ro': 'Romanian',
  download: 'Descarca',
  upload: 'Incarca',
  partnerDeleteQuestion: 'Esti sigur ca vrei sa stergi partenerul',
  editedPartner: 'Partener editat',
  addedPartner: 'Partener adaugat',
  documentsAppearAfterProcessing: 'Fisierele vor aparea in lista indata ce vor fi procesate',
  group: 'Group',
  enrollEFactura: 'Înrolare e-Factura',
  eFacturaSuccessfulEnrollment: 'Ați fost înrolat cu success în e-Factura',
  eFacturaFailedEnrollment: 'Înrolarea în e-Factura a eșuat',
  eFacturaFailedEnrollmentNotLoggedIn:
    'Înrolarea în e-Factura a eșuat, nu sunteți logat in Portal pentru a continua operațiunea',
  clientPassword: 'Parola client',
  sentDocuments: 'Documente trimise ({{count}})',
  syncEFactura: 'Sincronizează cu e-Factura',
  syncEFacturaSuccessful: 'Sincronizarea cu e-Factura a derulat cu success',
  syncEFacturaError: 'Sincronizarea cu e-Factura a eșuat',
  lastEnrollment: 'Ultima înrolare',
  status: 'Statut',
  evolution: 'Evoluţie',
  accessLevels: 'Niveluri de acces',
  name: 'Nume',
  partner: 'Partener',
  lists: 'Liste',
  created: 'Creat',
  search: 'Căutare',
  selectedElement: '{{count}} {{name}} selectate',
  selectedElements: '{{count}} {{name}} selectate',
  accessLevel: 'Nivel de acces',
  users: 'Utilizatori',
  addAccessLevel: 'Adăugați nivel de acces',
  editAccessLevel: 'Editați nivelul de acces',
  workspaceTagName: 'Numele etichetei spațiului de lucru',
  workspaceDescription: 'Descrierea spațiului de lucru',
  deleteAccessLevel: 'Ștergeți nivelul de acces',
  accessLevelDeleteQuestion: 'Sigur doriți să ștergeți nivelul de acces?',
  deletedAccessLevel: 'Nivel de acces șters',
  loadMore: 'Incarca mai mult',
  editedAccessLevel: 'Nivel de acces editat',
  addedAccessLevel: 'Nivel de acces adaugat',
  editUser: 'Editare utilizator',
  addUser: 'Adaugare utilizator',
  editedAccessLevelGroup: 'Grup de nivel de acces editat',
  addedAccessLevelGroup: 'Grup de nivel de acces adaugat',
  addAccessLevelGroup: 'Adăugați grup de nivel de acces',
  editAccessLevelGroup: 'Editați grup de nivel de acces',
  deletedAccessLevelGroup: 'Grup de nivel de acces șters',
  accessLevelGroupDeleteQuestion: 'Sigur doriți să ștergeți grupul de nivel de acces?',
  deleteAccessLevelGroup: 'Ștergeți grupul de nivel de acces',
  noAccessLevelsDescription:
    'Pentru a începe să vedeți o acțiune, vă rugăm să adăugați primul nivel de acces.',
  access: 'Acces',
  pendingApproval: 'În așteptare',
  pendingApprovalLabel: 'Documente În așteptare',
  nextFile: 'Următorul fișier',
  reject: 'Respinge',
  approve: 'Aprobă',
  moveFile: 'Mută fișier',
  move: 'Mută',
  addFolder: 'Adauga dosar',
  fromFolder: 'Din dosarul:',
  toFolder: 'În dosarul:',
  addNewFolderPlaceholder: 'Adaugă nou dosar',
  searchInputPlaceholder: 'Caută dosar',
  movedToFolderNotification: 'Fișierul a fost mutat cu succes în {{folder}}',
  moveMultipleFilesTitle: 'Mutați {{count}} fișiere',
  selectPartnerMessage: 'Pentru a continua, vă rugăm să selectați un partener.',
  cannotMoveReadonlyMessage:
    'Vă rugăm să eliminați toate fișierele numai pentru citire din selecție înainte de a continua.',
  folderCreateNotification: 'Dosarul a fost creat cu succes',
  approveFolderDelete: 'Esti sigur ca vrei sa stergi folderul?',
  deleteFolder: 'Sterge folder',
  permanentlyDeletedAdminFolder:
    'Acest folder va fi sters definitiv de pe acest cont si de pe contul partenerului. Esti sigur ca vrei sa stergi?',
  permanentlyDeletedFolder:
    'Acest folder va fi sters definitiv de pe acest cont si de pe contul partenerului. Esti sigur ca vrei sa stergi?',
  delegateApproval: 'În aprobarea delegatului',
  delegateApprovalLabel: 'Documente În aprobarea delegatului',
  delegate: 'Deleaga',
  cancelDelegate: 'Anulează delegarea',
  delegateToOther: 'Delegați altora',
  delegated: 'Delegat',
  confirm: 'Confirm',
  waiting: 'În așteptare',
  approved: 'Aprobat',
  expired: 'Expirat',
  delegateNotification: 'Documentul a fost delegat cu succes',
  delegateCancelNotification: 'Delegarea documentului a fost anulată cu succes',
  interestingFolderName: 'Nume de folder interesant',
  folders: 'Foldere',
  thisIsDocument: 'Acesta este un document ',
  typeFolderName: 'Tastați numele folderului',
  clearAll: 'Curață tot',
  selectAll: 'Selectează tot {{count}}',
  partnersCount: 'Numărul de parteneri',
  reloadBrowser:
    'Sesiune terminată, pentru a iniția o nouă sesiune, va rugăm să închideți și să redeschideți browser-ul',
  documentIsPending: 'Documentul așteaptă să fie revizuit',
  documentWasDelegated: 'Documentul a fost delegat pentru revizuire',
  myPartnerProfile: 'Profilul Meu',
  myClientProfile: 'Profil Admin',
  archive: 'Arhiva',
  storageLimitAlert: 'Ați utilizat peste 80% din spațiul de stocare alocat în SmartPortal.',
  mentenanteMessage:
    'Vă reamintim că, în urma modificărilor efectuate în SPV, este necesar să vă reautorizați (înrolați) token-ul pentru conectare',
  totalDownloadedDocuments: '{{downloaded}} documente pregătite din {{total}}',
  downloadProgress: 'Progresul descărcării',
  selectCategory: 'Selectați categoria',
  selectIzitCategoryInvoice: 'Categoriile de facturi care sunt trimise către Izit',
  others: 'Altele',
  received: 'Primite',
  issued: 'Emise',
  wrongDocumentType: 'Puteți aproba doar documente în format PDF cu statut (În așteptare).',
  comments: 'Comentarii',
  print: 'Printează',
  sendToEmail: 'Trimite pe email',
  sentEmail: 'Email trimis'
};
