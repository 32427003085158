import { useContext } from 'react';
import { Button, Form, Space } from 'antd';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';

import { documents } from 'api';
import { NotificationContext } from 'contexts';
import { formErrorHandler, getNotificationError } from 'utils';
import { DownloadFiles, SendToEmailBody } from 'api/documents.types';

import { Input } from './Input/Input';
import { Modal, ModalProps } from './Modal';

interface Props extends ModalProps {
  params?: DownloadFiles | null;
  onCancel?: () => void;
  onSuccess?: () => void;
}

export const SendToEmail = ({ params, onCancel, onSuccess, ...props }: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { notification } = useContext(NotificationContext);

  const { mutate: sendToEmailMutate, isLoading } = useMutation(documents.sendToEmail, {
    onSuccess: () => {
      notification.success({
        message: t('sentEmail'),
        placement: 'bottomRight'
      });
      onSuccess?.();
      onCancel?.();
    },

    onError: (error: AxiosError) => {
      form.setFields(formErrorHandler(error));
      notification.error({ message: getNotificationError(error) });
    }
  });

  const onCancelClick = () => {
    onCancel?.();

    form.resetFields();
  };

  const handleSubmit = (body: SendToEmailBody) => {
    if (!params) return;

    sendToEmailMutate({ body, params });
  };

  return (
    <Modal
      title={t('sendToEmail')}
      onCancel={onCancelClick}
      open={!!params}
      footer={
        <Space style={{ width: '100%', justifyContent: 'space-between' }}>
          <Button type="link" onClick={onCancelClick}>
            {t('cancel')}
          </Button>
          <Button type="primary" htmlType="submit" form="send-to-email-form" loading={isLoading}>
            {t('save')}
          </Button>
        </Space>
      }
      {...props}
    >
      <Form form={form} layout="vertical" id="send-to-email-form" onFinish={handleSubmit}>
        <Form.Item label={t('emailAddress')} name="email" required>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
