/** @jsxImportSource @emotion/react */
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, Divider, Form } from 'antd';
import { AxiosError } from 'axios';
import { documentColor, DocumentType, DocumentTypeIcon, Flex, Input, Modal } from 'components';
import { NotificationContext, ThemeContext } from 'contexts';
import { getNotificationError, getRoute, mimeTypeToDocumentType, withValues } from 'utils';
import { APIDocument, DocumentStatus } from 'types';
import { ShortRight } from 'icons';
import { documents, documentsQueries } from 'api';
import { stylesInit } from './DocumentHeader.styles';
import { usePrintDocument, useQueryParams } from 'hooks';

const PDF_DOCUMENT_TYPE = 'e_factura_pdf';
const formName = 'delegate-form';

interface DocumentHeaderInterface {
  document: APIDocument;
}

const documentTypes: { [key in DocumentType]: string } = {
  pdf: 'Acrobat DPF',
  doc: 'Text doc',
  img: 'Imagine',
  unknown: 'necunoscut'
};

export const DocumentHeader = ({ document }: DocumentHeaderInterface) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const queryParams = useQueryParams();
  const location = useLocation();

  const { currentTheme } = useContext(ThemeContext);
  const { notification } = useContext(NotificationContext);

  const { print, canPrint, loading } = usePrintDocument();

  const styles = stylesInit(currentTheme);
  const documentType = mimeTypeToDocumentType(document.title) || 'unknown';

  const [delegateModalState, setDelegateModalState] = useState(false);

  const isDelegated = document.state === DocumentStatus.delegateApproval;
  const delegateButtonText = isDelegated ? 'cancelDelegate' : 'delegate';

  const query = useInfiniteQuery({
    ...documentsQueries.getList(
      withValues({
        ...queryParams,
        state: queryParams.documents_state,
        type: PDF_DOCUMENT_TYPE,
        per_page: 1,
        page: queryParams.__page
        // exclude_ids: document.id
      })
    ),

    getNextPageParam: (currentPage) => {
      if (
        currentPage.current_page >= currentPage.total_pages &&
        currentPage.results[0]?.id !== document?.id
      ) {
        currentPage.current_page = 1;
      }

      const nextPage =
        currentPage.current_page < currentPage.total_pages
          ? currentPage.current_page + 1
          : undefined;

      return nextPage;
    },

    keepPreviousData: false,
    enabled: !!document.id
  });

  const navigateToNextDocument = async (reset?: boolean) => {
    const nextDocument = await query.fetchNextPage();

    if (nextDocument.hasNextPage || nextDocument.isSuccess) {
      const id =
        nextDocument?.data?.pages?.[nextDocument?.data?.pages?.length - 1]?.results?.[0]?.id;

      id &&
        navigate(
          getRoute('PartnerDocumentItemPage', {
            query: {
              ...queryParams,
              __page: reset ? 1 : nextDocument?.data?.pageParams[1]
            },
            id
          }),
          { state: { lastDocumentId: document.id } }
        );
    }

    invalidateDocumentQueries();
  };

  useEffect(() => {
    const lastDocumentId = location.state?.lastDocumentId;

    if (!document.id || !lastDocumentId || !query.hasNextPage) return;

    if (lastDocumentId === document.id) {
      navigateToNextDocument();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state?.lastDocumentId, document.id, query.hasNextPage]);

  const invalidateDocumentQueries = () => {
    queryClient.clear();
  };

  const sendToApolloMutation = useMutation(documents.sendToApollo, {
    onSuccess: () => {
      notification.success({
        message: t('sentDocuments', { count: 1 }),
        placement: 'bottomRight'
      });

      navigateToNextDocument(true);
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
    }
  });

  const { mutate: delegateCreateMutation, isLoading: isLoadingCreateDelegate } = useMutation(
    documents.createDelegatedDocument,
    {
      onSuccess: () => {
        notification.success({
          message: t('delegateNotification'),
          placement: 'topRight'
        });

        setDelegateModalState(false);

        invalidateDocumentQueries();
      },

      onError: (error: AxiosError) => {
        notification.error({
          message: getNotificationError(error)
        });
      }
    }
  );

  const { mutate: cancelDelegateMutation, isLoading: isLoadingCancelDelegate } = useMutation(
    documents.cancelDelegatedDocument,
    {
      onSuccess: () => {
        notification.success({
          message: t('delegateCancelNotification'),
          placement: 'topRight'
        });

        invalidateDocumentQueries();
      },

      onError: (error: AxiosError) => {
        notification.error({
          message: getNotificationError(error)
        });
      }
    }
  );

  const rejectDocumentMutation = useMutation(documents.rejectDocument, {
    onSuccess: () => {
      notification.success({
        message: t('rejectDocument'),
        placement: 'bottomRight'
      });

      navigateToNextDocument(true);
    },
    onError: (error: AxiosError) => {
      notification.error({
        message: getNotificationError(error)
      });
    }
  });

  const onClickCancelDelegate = () => {
    const lastDelegatedDocument =
      document?.delegated_approvals?.[document?.delegated_approvals?.length - 1]?.id;

    if (lastDelegatedDocument) {
      cancelDelegateMutation(lastDelegatedDocument);
    }
  };

  const onSubmitDelegateForm = ({ email }: { email: string }) => {
    delegateCreateMutation({ document: document.id, email });
  };

  return (
    <>
      <Flex gap="8px" justify="space-between" css={styles.Container}>
        <Flex gap="8px">
          <DocumentTypeIcon
            bgColor={'transparent'}
            color={documentColor[documentType]}
            size={2}
            type={documentType}
          />
          <span css={styles.Title}>Acesta este un document {documentTypes[documentType]}</span>
        </Flex>

        {(document.state === DocumentStatus.pendingApproval || isDelegated) &&
          document.type === PDF_DOCUMENT_TYPE && (
            <Flex gap="16px" justify="end">
              <Button
                css={styles.Button}
                icon={<ShortRight />}
                type="link"
                onClick={() => navigateToNextDocument()}
                disabled={!query.hasNextPage}
                loading={query.isLoading || query.isFetching || query.isFetchingNextPage}
              >
                {t('nextFile')}
              </Button>

              <Button
                onClick={() => rejectDocumentMutation.mutate(document.id)}
                loading={rejectDocumentMutation.isLoading}
                disabled={isDelegated}
              >
                {t('reject')}
              </Button>

              <Button
                css={styles.Button}
                type="primary"
                icon={!isDelegated && <ShortRight />}
                loading={isLoadingCreateDelegate || isLoadingCancelDelegate}
                onClick={() =>
                  isDelegated ? onClickCancelDelegate() : setDelegateModalState(true)
                }
              >
                {t(delegateButtonText)}
              </Button>

              <Button
                onClick={() => sendToApolloMutation.mutate({ ids: [document.id], exclude_ids: [] })}
                type="primary"
                loading={sendToApolloMutation.isLoading}
                disabled={isDelegated}
              >
                {t('approve')}
              </Button>
            </Flex>
          )}

        {canPrint(document) && (
          <Button type="primary" onClick={() => print({ id: document.id })} loading={loading}>
            {t('print')}
          </Button>
        )}
      </Flex>

      {document.state === DocumentStatus.pendingApproval && (
        <Modal
          onCancel={() => setDelegateModalState(false)}
          title={t('delegateToOther')}
          open={delegateModalState}
          destroyOnClose
          centered
          footer={[
            <Flex justify="space-between">
              <Button type="text" onClick={() => setDelegateModalState(false)}>
                {t('cancel')}
              </Button>

              <Button type="primary" htmlType="submit" form={formName}>
                {t('delegate')}
              </Button>
            </Flex>
          ]}
        >
          <Form onFinish={onSubmitDelegateForm} name={formName} layout="vertical">
            <Form.Item
              name="email"
              label={t('emailAddress')}
              rules={[
                {
                  required: true,
                  message: t('pleaseInsertEmail'),
                  type: 'email'
                }
              ]}
            >
              <Input placeholder={t('inputEmail')} />
            </Form.Item>
          </Form>

          <Divider />
        </Modal>
      )}
    </>
  );
};
